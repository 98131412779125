<template>
  <div>
    <div class="card">
      <div class="card-body">
        <h4 class="card-title">
          <a
            href="#"
            @click.prevent="show_create = true"
            class="btn btn-primary btn-xs float-right"
            ><i class="lni-plus mr-2"></i> Add New</a
          >
          Callback URLs
        </h4>
        <table class="table table-striped">
          <thead>
            <tr>
              <th>Event</th>
              <th>Url</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr v-if="callbacks.length == 0">
              <td colspan="2">There is no data to display</td>
            </tr>
            <tr v-for="(callback, i) in callbacks" :key="`callback-${i}`">
              <td>{{ callback.event }}</td>
              <td>{{ callback.url }}</td>
              <td>
                <a href="#" class="btn btn-danger btn-xs" @click.prevent="remove(callback)"><i class="lni-trash"></i></a>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <modal :show="show_create" @close="show_create = false">
      <template slot="header">Add Callback URL</template>
      <form @submit.prevent="submit">
        <div class="form-group">
          <label>Event</label>
          <select class="form-control" v-model="new_callback.event">
            <option value="bulk-sms-delivery-report">SMS delivery report</option>
          </select>
        </div>
        <div class="form-group">
          <label>Endpoint URL</label>
          <input type="text" placeholder="https://my-website.domain/endpoint" class="form-control" v-model="new_callback.url">
        </div>
        <div class="form-group text-right">
          <button class="btn btn-primary btn-sm"><i class="lni-save mr-2"></i>Save</button>
        </div>
      </form>
    </modal>
  </div>
</template>

<script>
export default {
  mounted() {
    this.fetch()
  },

  data() {
    return {
      show_create: false,
      callbacks: [],
      new_callback: { event: 'bulk-sms-delivery-report' }
    };
  },

  methods: {
    fetch() {
      this.$loader.start('main-content')

      this.$axios.get(`/api/v1/apps/${this.$route.params.id}/callbacks`).then(response => {
        this.callbacks = response.data
        this.$loader.stop()
      })
    },

    submit() {
      this.$loader.start('main-content')

      this.$axios.post(`/api/v1/apps/${this.$route.params.id}/callbacks`, this.new_callback).then(response => {
        this.callbacks = response.data
        this.$loader.stop()
        this.show_create = false
      })
    },

    remove(callback) {
      this.$loader.start('main-content')

      this.$axios.post(`/api/v1/apps/${this.$route.params.id}/delete-callback/${callback.id}`).then(response => {
        this.callbacks = response.data
        this.$loader.stop()
      })
    }
  }
};
</script>
